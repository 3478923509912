<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        Bank Status
      </div>
    </div>
    <table class="table">
      <thead>
        <tr :key="'xxx'">
          <th>ธนาคาร</th>
          <th>เลขบัญชี</th>
          <th>ชื่อบัญชี</th>
          <th>ยอดเงิน</th>
          <th>สถานะใช้ในการฝาก</th>
          <th>ประเภทบัญชี</th>
          <th>Last Successful Synced</th>
        </tr>
      </thead>
      <!-- แบบใหม่ pg ใช้หัวเดียวอยู่ -->
      <tbody>
        <tr
          v-for="(item) in agent.BankData"
          :key="item.name"
        >
          <td><img
            :src="`/bankIcon/${item.bank_path_photo}`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          ></td>
          <td>{{ item.acc_number }}</td>
          <td>{{ item.acc_name }}</td>
          <td>{{ item.balance ? item.balance: '-' }}</td>
          <td>
            <span
              v-if="item.deposit_status === 1"
              class="text-success"
            >เปิด</span>
            <span
              v-else
              class="text-danger"
            >ปิด</span>
          </td>
          <td class="text-success">
            ฝาก
          </td>
          <td>-</td>
        </tr>
        <tr v-if="agent && agent.auto_wit_scb">
          <td><img
            :src="`/bankIcon/scb.png`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          ></td>
          <td>{{ agent.auto_wit_scb.accno }}</td>
          <td>{{ agent.auto_wit_scb.accname }}</td>
          <td>{{ parseFloat(agent.withdraw_scb_balance).toFixed(2).toLocaleString() }}</td>
          <td>
            <span
              v-if="agent.checktruewallet"
              class="text-success"
            >เปิด</span>
            <span
              v-else
              class="text-danger"
            >ปิด</span>
          </td>
          <td class="text-danger">
            ถอน
          </td>
          <td> -</td>
        </tr>
      </tbody>
      <!-- แบบเก่า -->
      <!-- <tbody v-if="agent.agent_id != 2 && agent.agent_id != 6">
        <tr v-if="agent && agent.dip_bank_accno">
          <td><img
            :src="`/bankIcon/scb.png`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          ></td>
          <td>{{ agent.dip_bank_accno }}</td>
          <td>{{ agent.dip_bank_accname }}</td>
          <td>{{ agent.deposit_balance }}</td>
          <td>
            <span
              v-if="agent.checkbank1 === 1"
              class="text-success"
            >เปิด</span>
            <span
              v-else
              class="text-danger"
            >ปิด</span>
          </td>
          <td class="text-success">
            ฝาก
          </td>
          <td />
        </tr>
        <tr v-if="agent && agent.dip_bank2_accno">
          <td><img
            :src="`/bankIcon/scb.png`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          ></td>
          <td>{{ agent.dip_bank2_accno }}</td>
          <td>{{ agent.dip_bank2_accname }}</td>
          <td>{{ agent.deposit2_balance }}</td>
          <td>
            <span
              v-if="agent.checkbank2 === 1"
              class="text-success"
            >เปิด</span>
            <span
              v-else
              class="text-danger"
            >ปิด</span>
          </td>
          <td class="text-success">
            ฝาก
          </td>
          <td />
        </tr>
        <tr v-if="agent && agent.dip_bank3_accno">
          <td><img
            :src="`/bankIcon/scb.png`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          ></td>
          <td>{{ agent.dip_bank3_accno }}</td>
          <td>{{ agent.dip_bank3_accname }}</td>
          <td>{{ agent.deposit2_balance }}</td>
          <td>
            <span
              v-if="agent.checkbank3 === 1"
              class="text-success"
            >เปิด</span>
            <span
              v-else
              class="text-danger"
            >ปิด</span>
          </td>
          <td class="text-success">
            ฝาก
          </td>
          <td />
        </tr>
        <tr v-if="agent && agent.truemoney_acc">
          <td><img
            :src="`/bankIcon/true.svg`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          ></td>
          <td>{{ agent.truemoney_acc }}</td>
          <td>{{ agent.truemoney_name }}</td>
          <td>{{ parseFloat(agent.truemoney_balance).toFixed(2).toLocaleString() }}</td>
          <td>
            <span
              v-if="agent.checktruewallet"
              class="text-success"
            >เปิด</span>
            <span
              v-else
              class="text-danger"
            >ปิด</span>
          </td>
          <td class="text-success">
            ฝาก
          </td>
          <td />
        </tr>
        <tr v-if="agent && agent.auto_wit_scb">
          <td><img
            :src="`/bankIcon/scb.png`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          ></td>
          <td>{{ agent.auto_wit_scb.accno }}</td>
          <td>{{ agent.auto_wit_scb.accname }}</td>
          <td>{{ parseFloat(agent.withdraw_scb_balance).toFixed(2).toLocaleString() }}</td>
          <td>
            <span
              v-if="agent.checktruewallet"
              class="text-success"
            >เปิด</span>
            <span
              v-else
              class="text-danger"
            >ปิด</span>
          </td>
          <td class="text-danger">
            ถอน
          </td>
          <td />
        </tr>
      </tbody> -->
    </table>
  </div>
</template>

<script>
export default {
  props: ['agent'],
}
</script>
